<template>
  <div>
    <div class="bg-gradient-to-br from-blue-100 via-teal-200 to-blue-300 text-blue-700">
      <div class="container mx-auto pt-32 pb-12 md:flex">
        <div class="w-full md:w-1/2 px-3">
          <h2 class="font-bold text-3xl my-3">Who we are</h2>
          <p class="font-medium leading-loose tracking-wide">
            We are glad you are here; whether you have used our services before or are just getting started, it is time
            to
            get acquainted, especially since our team is available 24/7 to work on your orders or respond to your
            inquiries. Our goal is to provide you with a positive user experience.
          </p>
        </div>
        <div class="w-full md:w-1/2 transform -translate-y-48 md:-translate-y-0">
          <img alt="blue hacker guy" class="w-full h-full" src="../assets/svgs/blue-hacker.svg">
        </div>
      </div>
    </div>
    <div class="bg-gradient-to-r bg-gray-400">
      <div class="container mx-auto px-2 py-10 bg-gray-300 rounded-lg transform -translate-y-48 md:-translate-y-10">
        <div>
          <h2 class="text-center font-bold text-5xl my-4 text-transparent bg-clip-text bg-gradient-to-br from-blue-300 via-teal-400 to-blue-600">
            Let's dive into some numbers</h2>
          <p class="text-center mb-8 text-xl">
            We have been involved in academic writing since 2017, and here are some of our accomplishments to date.
          </p>
        </div>
        <our-numbers/>
        <!--        <div class="md:flex space-x-4 items-center justify-between">-->
        <!--          <div class="py-4 flex flex-col items-center">-->
        <!--            <h2 class="text-8xl text-green-600 font-medium">15</h2>-->
        <!--            <p>qualified specialists</p>-->
        <!--          </div>-->
        <!--          <div class="py-4 flex flex-col items-center">-->
        <!--            <h2 class="text-8xl text-green-600 font-medium">9.0/10</h2>-->
        <!--            <p>satisfaction rate</p>-->
        <!--          </div>-->
        <!--          <div class="py-4 flex flex-col items-center">-->
        <!--            <h2 class="text-8xl text-green-600 font-medium">2,700+</h2>-->
        <!--            <p>custom papers</p>-->
        <!--          </div>-->
        <!--          <div class="py-4 flex flex-col items-center">-->
        <!--            <h2 class="text-8xl text-green-600 font-medium">5</h2>-->
        <!--            <p>-->
        <!--              years helping academics-->
        <!--            </p>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <AJourney/>
    <div class="bg-blue-100 -mt-24">
      <div class="container mx-auto py-12 md:flex items-center justify-between">
        <div class="md:w-1/2 px-4">
          <h3 class="my-3 font-medium text-2xl">Get help with your assignment from <i class="text-4xl text-blue-500">experienced
            writers</i></h3>
          <button class="my-3 bg-blue-600 text-white px-8 py-3 font-bold rounded-full hover:bg-blue-500">Place an
            order
          </button>
        </div>
        <div class="md:w-1/2">
          <img alt="relaxed deadlines clock" class="w-full h-full" src="../assets/svgs/relaxed-deadlines.svg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AJourney from "@/components/layout/AJourney";
import OurNumbers from "@/components/layout/OurNumbers";

export default {
  components: {OurNumbers, AJourney},
  metaInfo: {
    title: "About us CustomWritingHive.com"
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
<style type="text/css">
.st0 {
  fill: #9A3B82;
}

.st1 {
  fill: #FFCF2D;
}

.st2 {
  fill: #FFFFFF;
  stroke: #FFCF2D;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st3 {
  fill: none;
  stroke: #FFCF2D;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st4 {
  fill: #66B0F0;
  stroke: #FFCF2D;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st5 {
  fill: #FE5244;
}

.st6 {
  fill: #A0505A;
}

.st7 {
  fill: #73325A;
}

.st8 {
  fill: none;
  stroke: #73325A;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st9 {
  fill: none;
  stroke: #FFCF2D;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st10 {
  fill: #FFFFFF;
}

.st11 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st12 {
  fill: #66B0F0;
}

.st13 {
  fill: #3FB1B5;
}

.st14 {
  fill: none;
  stroke: #66B0F0;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st15 {
  fill: none;
  stroke: #FE5244;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st16 {
  fill: none;
  stroke: #FE5244;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st17 {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st18 {
  fill: #E57281;
}

.st19 {
  fill: none;
  stroke: #9A3B82;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.st20 {
  fill: #66B0F0;
  stroke: #FFFFFF;
  stroke-width: 6;
  stroke-miterlimit: 10;
}

.st21 {
  fill: #FFFFFF;
  stroke: #FE5244;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st22 {
  fill: none;
  stroke: #73325A;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
